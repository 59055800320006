import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppGuard } from '@shared/guards';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';

import { Bracelit404Component } from '@app/shared/bee-components/bracelit-404';

import { BracelitAuthGuard } from './bracelit-auth-guard';

const routes: Routes = [
  // {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  //{path: '', redirectTo: 'welcome', pathMatch: 'full'},
  {
    path: 'qr-inicial',
    loadChildren: () => import('app/shared/components/qr-scan/qr-scan.module').then(m => m.QrScanModule)
  },
  {
    path: 'mas-eventos',
    loadChildren: () => import('app/shared/components/more-events/more-events.module').then(m => m.MoreEventsModule)
  },
  {
    path: 'nueva-pulsera',
    loadChildren: () => import('app/shared/components/new-wristband/new-wristband.module').then(m => m.NewWristbandModule)
  },
  {path: 'login', loadChildren: () => import('app/auth/login/login.module').then(m => m.LoginModule)},
  {path: 'login/:associating_id', loadChildren: () => import('app/auth/login/login.module').then(m => m.LoginModule)},
  {
    path: 'welcome',
    loadChildren: () => import('app/auth/welcome-page/welcome-page.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('app/auth/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'registro/:associating_id',
    loadChildren: () => import('app/auth/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'recuperacion',
    loadChildren: () => import('app/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reenvio',
    loadChildren: () => import('app/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('app/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'confirmacion/:token',
    loadChildren: () => import('app/auth/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)
  },
  {
    path: 'confirmacion-credenciales/:token',
    loadChildren: () => import('app/auth/first-password/first-password.module').then(m => m.FirstPasswordModule)
  },
  {
    path: 'home',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
    canActivate: [BracelitAuthGuard]
  },

  {path: '**', component: Bracelit404Component, data: {animation: {page: 'notFoundPage'}}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppCustomPreloader,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top'
})],
  exports: [RouterModule],
  providers: [
    AppGuard,
    BracelitAuthGuard
  ]
})
export class AppRoutingModule {
}
