<bracelit-preloader *ngIf="loading" [absoluteCentered]="true"></bracelit-preloader>

<div fxLayout="row wrap" *ngIf="this.data.isMoneyReturned">
    <!--<div id="close-container" *ngIf="!loading" fxFlex="100" fxLayout="row" fxLayoutAlign="end center">
      <mat-icon svgIcon="close" (click)="dialogRef.close()"></mat-icon>
    </div>-->
    <div style="display: flex !important; align-items:center !important; justify-content:right !important;
      height:fit-content !important; width:100% !important;"
      (click)="dialogRef.close()">
      <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
    </div>
    <div class="bold-16px-center-marine"
      style="display: flex !important; align-items:center !important; justify-content:center !important;
        height:fit-content !important; width:100% !important; margin: 15px 0 -10px 0;">
      {{request}}
    </div>
    <bee-form fxFlex="100"
          *ngIf="!loading"
          [backEnabled]="false"
          [deleteEnabled]="false"
          [customSubmit]="true"
          [beeFormGroup]="myForm"
          [method]="'post'"
          [saveButtonText]="requestButton"
          [submitButtonClasses]="'primary-background bold-20px-center-white
          '"
          (responseOutput)="save()">
      <ng-container *ngIf="myForm.controls['extraFields'] != undefined">
      <bee-form-item [appearance]="'outline'"
                  [beeFormControl]="myForm.controls['extraFields']['controls']['name']"
                  [id]="'event-form-name'"
                  [placeholder]="nameText"
                  [type]="'text'"
                  fxFlex="100"></bee-form-item>
      <bee-form-item [appearance]="'outline'"
                  [beeFormControl]="myForm.controls['extraFields']['controls']['surname']"
                  [id]="'event-form-surname'"
                  [placeholder]="surnameText"
                  [type]="'text'"
                  fxFlex="100"></bee-form-item>
      <bee-form-item [appearance]="'outline'"
                  [beeFormControl]="myForm.controls['extraFields']['controls']['iban']"
                  [id]="'event-form-iban'"
                  [placeholder]="ibanText"
                  [type]="'text'"
                  fxFlex="100"></bee-form-item>
      <bee-form-item *ngIf="!hasQR" [appearance]="'outline'"
                  [beeFormControl]="myForm.controls['extraFields']['controls']['code']"
                  [id]="'event-form-code'"
                  [placeholder]="codeText"
                  [type]="'text'"
                  fxFlex="100"></bee-form-item>
      </ng-container>
    </bee-form>
  </div>