import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BeeIBANValidator } from '@bee-components/common/bee-validators';

import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';
import { EventUser } from '@shared/models/bracelit/event-user';

@Component({
  selector: 'app-money-return',
  templateUrl: './money-return.component.html',
  styleUrls: ['./money-return.component.scss']
})
export class MoneyReturnComponent implements OnInit {
  myForm: FormGroup;
  eventId: string;
  loading = true;
  eventForm: any[] = [];
  eventUser: EventUser;
  message: string;
  eventName: string;
  request = (localStorage.getItem('lang') === 'es') ? 'Rellene los datos para solicitar devolución' : 'Fill in the data to request a refund';
  requestButton = (localStorage.getItem('lang') === 'es') ? 'Solicitar' : 'Request';
  nameText = (localStorage.getItem('lang') === 'es') ? 'Nombre' : 'Name';
  surnameText = (localStorage.getItem('lang') === 'es') ? 'Apellidos' : 'Last name';
  ibanText = (localStorage.getItem('lang') === 'es') ? 'Número de cuenta bancaria (IBAN)' : 'Bank account number (IBAN)';
  codeText = (localStorage.getItem('lang') === 'es') ? 'Código de la pulsera (impreso por detrás del chip)' : 'Code (printed on the back of the chip)';
  hasQR: boolean;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<MoneyReturnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  /*this.myForm = formBuilder.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    iban: ['', Validators.required],
    code: ['', Validators.required],
  });*/
  this.myForm = new FormGroup({});
}

  ngOnInit(): void {
    localStorage.setItem('chinoCode', this.data.code1);
    localStorage.setItem('associatedQrId', this.data.code2);
    this.loadFormData();
  }

  async loadFormData() {
    this.eventId = this.data.eventId;
    const event = await this.apiService.get(`public/events/${this.eventId}`).toPromise();
    this.eventName = event.name;
    this.hasQR = event.hasQR;
    const clientId = this.authenticationService.client.id;
    const url = `clients/${clientId}/events/${this.eventId}/event-user`;
    this.eventUser = await this.apiService.get(url).toPromise();

    this.eventForm = JSON.parse(event.registerForm);
    if ((this.eventUser.registerExtraFields !== 'null') && (this.eventUser.registerExtraFields !== null) && (this.eventUser.registerExtraFields !== undefined)) {
      const formValues = JSON.parse(this.eventUser.registerExtraFields);
      const controls = {};
      controls['name'] = new FormControl(formValues['name'], Validators.required);
      controls['surname'] = new FormControl(formValues['surname'], Validators.required);
      controls['iban'] = new FormControl(formValues['iban'], Validators.compose([Validators.required, BeeIBANValidator.validator]));
      controls['code'] = new FormControl(formValues['code'], Validators.compose([Validators.required, BeeIBANValidator.validatorCode]));
      this.myForm.addControl('extraFields', new FormGroup(controls));
    } else {
      const controls = {};
      controls['name'] = new FormControl('', Validators.required);
      controls['surname'] = new FormControl('', Validators.required);
      controls['iban'] = new FormControl('', Validators.compose([Validators.required, BeeIBANValidator.validator]));
      controls['code'] = new FormControl(!this.hasQR ? '' : this.data.code1, Validators.compose([Validators.required, BeeIBANValidator.validatorCode]));
      this.myForm.addControl('extraFields', new FormGroup(controls));
    }
    this.loading = false;
  }

  async save() {
    if (this.myForm.valid) {
      let IBAN = this.myForm.value.extraFields.iban;
      IBAN = IBAN.toUpperCase();  // Todo a Mayus
      IBAN = IBAN.trim(); // Quitamos blancos de principio y final.
      IBAN = IBAN.replace(/\s/g, '');  // Quitamos blancos del medio.
      IBAN = IBAN.replace(/\-/g, ''); // Quitamos los guiones.
      this.myForm.value.extraFields.iban = IBAN;
      this.loading = true;
      const clientId = this.authenticationService.client.id;
      let accountId = JSON.parse(localStorage.getItem('accountId'));
      // PROVISIONAL
      this.loading = false;
      this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Su solicitud ha sido recibida con éxito.' : 'Your request has been received successfully.');
      this.dialogRef.close();

      await this.apiService.post(`clients/${clientId}/events/${this.eventId}/event-user/updateReturnMoney`, this.myForm.value).toPromise();
      const moneyFormat = Number(this.data.money).toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
      });
      
      if (this.data.isMoneyReturned && this.eventId !== 'd36ec06b-4409-4381-83e8-b69832ffa5ed') {
        this.apiService
        .post(`accounts/${accountId}/events/${this.eventId}/Mailing`, {subject: 'Confirmación de Devolución', subtitle: '¡Has realizado con éxito la solicitud de devolución de tu saldo restante de ' + this.eventName +'!', plaintext_body: 'Queremos informarte que las devoluciones pueden tardar hasta 15 días en hacerse efectivas. Se comenzarán a tramitar una vez finalice el plazo de solicitudes para todos los asistentes.\n\nPor favor, ten en cuenta que las devoluciones podrán incluir gastos de gestión y las promociones u ofertas pueden no ser reembolsables.\n\nSi tienes alguna pregunta, no dudes en contactarnos en soporte@bracelit.es', sender_email: 'notificaciones@bracelit.es', recipient_emails: [this.authenticationService.user['email']], timeToSend: Date.parse('yyyy-MM-dd H:mm'), returnMoney: true})
        .subscribe(_data => {
          this.dialogRef.close();
          this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Su solicitud ha sido recibida con éxito.' : 'Your request has been received successfully.');
        }, err => {
          this.dialogRef.close();
          this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'Se ha detectado un error. Por favor, inténtelo de nuevo en unos minutos.' : 'An error has been detected. Please, try again in a few minutes.');
          console.error(err);
        });
      } else {
        this.message = (localStorage.getItem('lang') === 'es') ? 'Se ha actualizado correctamente el perfil para el evento.' : 'Profile has been successfully updated.';
      }
    }
  }
}