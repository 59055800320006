import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsComponent } from '@shared/components/notifications/notifications.component';
import { Subscription } from 'rxjs';

import { AuthenticationService, ThemeService, ToggleSidebarService } from '@shared/services';
import { WristbandsService } from '@app/shared/services/wristbands.service';
import { DomainService } from '@app/shared/services/domain.service';
import { TranslateConfigService } from '@app/shared/services/translate-config.service';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import esJson from '../../../../assets/i18n/es.json';
import enJson from '../../../../assets/i18n/en.json';

@Component({
  selector: 'bracelit-mobile-header',
  templateUrl: './bracelit-mobile-header.component.html',
  styleUrls: ['./bracelit-mobile-header.component.scss']
})
export class BracelitMobileHeaderComponent implements OnInit {
  @Input() height = 50;
  @Input() central_text: string;
  @Input() central_icon: string; // url
  @Input() central_icon_with = 135; // url
  @Input() central_icon_height = 50; // url
  @Input() left_icon: string; // url
  @Input() left_back_icon: string; // url
  @Input() left_icon_height = 24;
  @Input() left_icon_width = 24;
  @Input() right_icon: string; // url
  @Input() right_icon_height = 24;
  @Input() right_icon_width = 24;
  @Input() sidebar = false;
  @Input() sidebar_margin_right = 25;
  @Input() sidebar_margin_left = 25;
  @Input() header_central_text_class = '';
  @Input() header_background_class = '';

  @Input() sidebar_notifications_number = 0; // number inside a badge

  @Input() sidebar_abilities = {};

  @Output() readonly onClickLeftButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly onClickCentralButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly onClickRightButton: EventEmitter<any> = new EventEmitter<any>();

  abilities = {};
  display_sidebar = false;
  collapse_tooltip: string;
  expand_tooltip: string;
  private subElements: any[];
  private toggle_sidebar_subscription: Subscription;
  private wristbands_subscription: Subscription;
  eventWristband: string;
  masterEvent:any;
  accountWristband: any;
  listEventWristband = [];
  listEventWristbandUrls = []
  events = [];
  langJson: any;
  sidebarAction = false;
  lang = localStorage.getItem('lang');
  flag = 'assets/icons/' + localStorage.getItem('lang') + '.svg';
  disabledES = localStorage.getItem('lang') === 'en';
  disabledEN = localStorage.getItem('lang') === 'es';
  type = (localStorage.getItem('lang') === 'es') ? 1 : 2;
  wristbandsListTitle = (localStorage.getItem('lang') === 'es') ? 'Mis pulseras' : 'My wallets';
  public accountLogo: string;
  abilities2: any;

  constructor(private _toggle_sidebar_service: ToggleSidebarService,
              private router: Router,
              private route: ActivatedRoute,
              private wristbandsService: WristbandsService,
              public dialog: MatDialog,
              private changeDetector_ref: ChangeDetectorRef,
              public authService: AuthenticationService,
              public domainService: DomainService,
              public themeService: ThemeService,
              private translateConfigService: TranslateConfigService,
              private _location: Location) {
      this.langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
      this.toggle_sidebar_subscription = _toggle_sidebar_service.sidebarStatusChanged$.subscribe(
        (value) => {
          this.display_sidebar = value;
          this.changeDetector_ref.detectChanges();
        });
      this.wristbands_subscription = this.wristbandsService.wristbandsChange.subscribe(
        (_value) => {
          this.abilities2 = {
            contact_list: {
              name: this.langJson.knowUs,
              route: '/home/listado-pulseras',
              icon: 'know_us',
              subElementsActive: true,
              subElements: [
                {
                  name: (localStorage.getItem('lang') === 'es') ? 'Conócenos' : 'Know us',
                  route: (localStorage.getItem('lang') === 'es') ? 'https://bracelit.es' : 'https://bracelit.es/en',
                },
                {
                  name: (localStorage.getItem('lang') === 'es') ? 'Soporte' : 'Support',
                  route: 'mailto:soporte@bracelit.es',
                }
              ],
              externalUrls: true,
              subElementsHeight: 50,
              subElementsTotalHeight: 150,
            }
          };
          this.loadWristbandSubElements();
          this.changeDetector_ref.detectChanges();
        });
  }

  ngOnInit() {
    this.accountLogo = localStorage.getItem('accountLogo');
    if (this.accountLogo === '') {
      this.accountLogo = 'assets/bracelit/new_logo_horizontal.png';
    }
    this.subElements = this.wristbandsService.getSubElements();
    this.createSideBarElements();

    this.abilities2 = {
      contact_list: {
        name: this.langJson.knowUs,
        route: '/home/listado-pulseras',
        icon: 'know_us',
        subElementsActive: true,
        subElements: [
          {
            name: (localStorage.getItem('lang') === 'es') ? 'Conócenos' : 'Know us',
            route: (localStorage.getItem('lang') === 'es') ? 'https://bracelit.es' : 'https://bracelit.es/en',
          },
          {
            name: (localStorage.getItem('lang') === 'es') ? 'Soporte' : 'Support',
            route: 'mailto:soporte@bracelit.es',
          }
        ],
        externalUrls: true,
        subElementsHeight: 50,
        subElementsTotalHeight: 150,
      }
    };
  }

  ngAfterViewChecked(): void {
    this.accountLogo = localStorage.getItem('accountLogo');
    if (this.accountLogo === '') {
      this.accountLogo = 'assets/bracelit/new_logo_horizontal.png';
    }
    this.changeDetector_ref.detectChanges();
  }

  redirectToBracelit() {
    window.open('http://bracelit.es', '_blank');
  }

  onNavigateConfiguration(value: string): void {
    this.router.navigate(['/home/configuracion/'+value], {relativeTo: this.route});
  }

  ngOnDestroy(): void {
    this.toggle_sidebar_subscription.unsubscribe();
  }

  associateWristband(){
    this.router.navigate([`/home/nueva-pulsera`], {relativeTo: this.route});
  }

  goToMoreEvents(): void {
    this.router.navigate([`/home/mas-eventos`], {relativeTo: this.route});
  }

  closeSession() {
    const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: (localStorage.getItem('lang') === 'es') ? '¿Está seguro de que desea cerrar sesión?' : 'Are you sure you want to close session?'
    });
    dialogRef2.afterClosed().subscribe(result => {
      if (result) {
        this.subElements = [];
        this.wristbandsService.wristbands = [];
        if (!this.domainService.event) {
          this.themeService.selectTheme('bracelit-theme');
          localStorage.setItem('accountLogo', '');
        }
      this.authService.logout('../login');
      }
    });
  }

  displaySidebarHeader(display_sidebar: boolean): boolean {
    return display_sidebar;
  }

  onSidebarElement($event): void {
  }

  loadWristbandSubElements(): void {
    this.subElements = [
      {
        name: (localStorage.getItem('lang') === 'es') ? 'Ver todas' : 'Show all',
        route: '/home/listado-pulseras',
        icon: 'clipboard-pulse-outline',
        createdAt: '3000-01-01 00:00:00',
        collapseOnClick: true
      }
    ];
    if (this.wristbandsService.wristbands.length !== 0) {
      for (const wristband of this.wristbandsService.wristbands) {
        //this.wristbandsService.fetchWristbandsWithEvent(wristband.id).subscribe(async (data) => {
          if(wristband.wallets[0].events.length>1){
            //OPTIMIZACIÓN GALLEGO: LA RUTA SIGUIENTE ANTES ESTABA FUERA DE ESTE IF, DE TAL MANERA QUE SE LLAMABA SIEMPRE, INDEPENDIENTEMENTE DE QUE SU RETURN SE USARA O NO. AHORA SOLO SE HACE LA LLAMADA SI SU VALOR SE VA A USAR, ES DECIR, CUANDO UNA WALLET TENGA MÁS DE UN EVENTO
            //OPTIMIZACIÓN GALLEGO: realmente toda este if se podría quitar puesto que "nunca"(?) tenemos más de un evento por cada wallet
            this.accountWristband = this.wristbandsService.fetchWristbandsWithAccounts(wristband.id);
            if(Object.keys(this.accountWristband).length === 1){
              let accountName = String(Object.keys(this.accountWristband));
              this.masterEvent = wristband.wallets[0].events.find(({name}) => name === accountName)
              this.eventWristband = this.masterEvent.name;
              this.events.push(wristband.wallets[0].events[0]);
            }else{
              this.eventWristband = wristband.wallets[0].events[0].name;
              this.events.push(wristband.wallets[0].events[0]);
            }
          }else{
            this.eventWristband = wristband.wallets[0].events[0].name;
            this.events.push(wristband.wallets[0].events[0]);
          }
          if (this.listEventWristband.indexOf(this.eventWristband) === -1) {
            this.subElements.push({
              name: this.eventWristband + ' (' + ((wristband.showName !== null && wristband.showName !== undefined && wristband.showName !== '') ? wristband.showName : wristband.chinoCode) + ')',
              route: `/home/pulseras/${wristband.id}`,
              icon: 'clipboard-pulse-outline',
              createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
              collapseOnClick: true
            });
            this.createSideBarElements();
            this.listEventWristband.push(this.eventWristband);
            this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
          } else {
            if(this.listEventWristbandUrls.indexOf(`/home/pulseras/${wristband.id}`) === -1){
              if(wristband.showName !== null && wristband.showName !== undefined && wristband.showName !== ''){
                this.subElements.push({
                  name: this.eventWristband + ' (' + wristband.showName + ')',
                  route: `/home/pulseras/${wristband.id}`,
                  icon: 'clipboard-pulse-outline',
                  createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
                  collapseOnClick: true
                });
                this.createSideBarElements();
                this.listEventWristband.push(this.eventWristband + ' (' + wristband.showName + ')');
                this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
              } else {
                this.subElements.push({
                  name: this.eventWristband + ' (' + wristband.chinoCode + ')',
                  route: `/home/pulseras/${wristband.id}`,
                  icon: 'clipboard-pulse-outline',
                  createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
                  collapseOnClick: true
                });
                this.createSideBarElements();
                this.listEventWristband.push(this.eventWristband + ' (' + wristband.chinoCode + ')');
                this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
              }
            }
          }
        //});
      }
    } else {
      this.createSideBarElements();
    }
  }

  onCollapseSidebar($event) {

    this.sidebarAction = (this._toggle_sidebar_service._sidebarActive)
    this.sidebarAction = !this.sidebarAction;
    this._toggle_sidebar_service.toggleSidebar(this.sidebarAction);
  }

  onNavigateHome($event): void {
    // this.router.navigate(['../home'], {relativeTo: this.route});
    this.router.navigate([`/home`], {relativeTo: this.route});
  }

  onClickButton(button: string) {
    switch (button) {
      case 'left-button' : {
        this.onClickLeftButton.emit('left-button');
        break;
      }
      case 'central-button' : {
        this.onClickCentralButton.emit('central-button');
        break;
      }
      case 'right-button' : {
        this.onClickRightButton.emit('right-button');
        break;
      }
      default:
        break;
    }
  }

  checkNotifications() {
    return this.sidebar_notifications_number > 0;
  }

  goToPreviousPage() {
    if (!window.location.href.includes('escanear-pulsera/resultado-qr')) {
      this._location.back();
      window.scroll(0, 0);
    } else {
      this.router.navigate(['home']);
    }
  }

  openNotifications(): void {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      minHeight: '65vh',
      maxHeight: '65vh',
      width: '500px',
      minWidth: '320px',
      panelClass: 'custom-modal-padding',
      data: {
        events: this.events
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  createSideBarElements(): void {
    this.subElements.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    this.wristbandsService.setSubElements(this.subElements);
    this.abilities = {
      wristband_list: {
        name: this.wristbandsListTitle,
        route: '/home/listado-pulseras',
        icon: 'wrist',
        subElementsActive: true,
        externalUrls: false,
        subElements: this.subElements,
        subElementsHeight: 50,
        subElementsTotalHeight: 150,
      }
    };
  }

  changeLang(type) {
    if (this.type !== type) {
      this.type = type;
    if (this.lang === 'es') {
      this.lang = 'en';
    } else if (this.lang === 'en') {
      this.lang = 'es';
    }
    if (type === 1) {
      this.disabledEN = !this.disabledEN;
      this.disabledES = !this.disabledES;
    }
    if (type === 2) {
      this.disabledES = !this.disabledES;
      this.disabledEN = !this.disabledEN;
    }
    this.flag = 'assets/icons/' + this.lang + '.svg';
    this.translateConfigService.changeLanguage(this.lang);
    this.wristbandsListTitle = (localStorage.getItem('lang') === 'es') ? 'Mis pulseras' : 'My wallets';
    if (this.domainService.event && !this.domainService.event.hasWristbands) {
      this.wristbandsListTitle = (localStorage.getItem('lang') === 'es') ? 'Mis wallets' : 'My wallets';
    }
    this.subElements = [
      {
        name: (localStorage.getItem('lang') === 'es') ? 'Ver todas' : 'Show all',
        route: 'listado-pulseras',
        icon: 'clipboard-pulse-outline',
        createdAt: '3000-01-01',
        collapseOnClick: true
      }
    ];
    this.listEventWristband = [];
    this.listEventWristbandUrls = [];
    if (this.wristbandsService.wristbands.length !== 0) {
      for (const wristband of this.wristbandsService.wristbands) {
        //this.wristbandsService.fetchWristbandsWithEvent(wristband.id).subscribe(async (data) => {
          if(wristband.wallets[0].events.length>1){
            //OPTIMIZACIÓN GALLEGO: LA RUTA SIGUIENTE ANTES ESTABA FUERA DE ESTE IF, DE TAL MANERA QUE SE LLAMABA SIEMPRE, INDEPENDIENTEMENTE DE QUE SU RETURN SE USARA O NO. AHORA SOLO SE HACE LA LLAMADA SI SU VALOR SE VA A USAR, ES DECIR, CUANDO UNA WALLET TENGA MÁS DE UN EVENTO
            //OPTIMIZACIÓN GALLEGO: realmente toda este if se podría quitar puesto que "nunca"(?) tenemos más de un evento por cada wallet
            this.accountWristband = this.wristbandsService.fetchWristbandsWithAccounts(wristband.id);
            if(Object.keys(this.accountWristband).length === 1){
              let accountName = String(Object.keys(this.accountWristband));
              this.masterEvent = wristband.wallets[0].events.find(({name}) => name === accountName)
              this.eventWristband = this.masterEvent.name;
              this.events.push(wristband.wallets[0].events[0]);
            }else{
              this.eventWristband = wristband.wallets[0].events[0].name;
              this.events.push(wristband.wallets[0].events[0]);
            }
          }else{
            this.eventWristband = wristband.wallets[0].events[0].name;
            this.events.push(wristband.wallets[0].events[0]);
          }
          if (this.listEventWristband.indexOf(this.eventWristband) === -1) {
            this.subElements.push({
              name: this.eventWristband,
              route: `/home/pulseras/${wristband.id}`,
              icon: 'clipboard-pulse-outline',
              createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
              collapseOnClick: true
            });
            this.createSideBarElements();
            this.listEventWristband.push(this.eventWristband);
            this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
          } else {
            if(this.listEventWristbandUrls.indexOf(`/home/pulseras/${wristband.id}`) === -1){
              if(wristband.showName !== null && wristband.showName !== undefined && wristband.showName !== ''){
                this.subElements.push({
                  name: this.eventWristband + ' (' + wristband.showName + ')',
                  route: `/home/pulseras/${wristband.id}`,
                  icon: 'clipboard-pulse-outline',
                  createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
                  collapseOnClick: true
                });
                this.createSideBarElements();
                this.listEventWristband.push(this.eventWristband + ' (' + wristband.showName + ')');
                this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
              } else {
                this.subElements.push({
                  name: this.eventWristband + ' (' + wristband.chinoCode + ')',
                  route: `/home/pulseras/${wristband.id}`,
                  icon: 'clipboard-pulse-outline',
                  createdAt: wristband.wallets[0].events[0].startingDate.toLocaleString('es'),
                  collapseOnClick: true
                });
                this.createSideBarElements();
                this.listEventWristband.push(this.eventWristband + ' (' + wristband.chinoCode + ')');
                this.listEventWristbandUrls.push(`/home/pulseras/${wristband.id}`);
              }
            }
          }
        //});
      }
    } else {
      this.subElements.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      this.abilities = {
        wristband_list: {
          name: this.wristbandsListTitle,
          route: 'listado-pulseras',
          icon: 'wrist',
          subElementsActive: true,
          externalUrls: false,
          subElements: this.subElements,
          subElementsHeight: 50,
          subElementsTotalHeight: 150,
        },
        scan_new_wristband: {
          name: (localStorage.getItem('lang') === 'es') ? 'Escanear un código nuevo' : 'Scan new code',
          route: 'escanear-pulsera',
          icon: 'scan_white',
        },
      };
    }
    }
  }
}
