import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';

import { SnackBarService } from '@shared/services';

import { BEE_FORM_ITEM_DEFAULT_APPEARANCE } from '@bee-components/common/bee-forms';

/**
 * BeeQrScanComponent.
 */
@Component({
  selector: 'bee-qr-scan',
  templateUrl: './bee-qr-scan.component.html',
  styleUrls: ['./bee-qr-scan.component.scss']
})
export class BeeQrScanComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() title: string;
  @Input() titleClass: string;
  @Input() titleMarginTop = 0;
  @Input() subTitle: string;
  @Input() subTitleClass: string;
  @Input() explanation: string;
  @Input() explanationClass: string;
  @Input() logo: string;
  @Input() scanFrameWidthPx = 280;
  @Input() scanFrameHeightPx = 280;
  @Input() verticalGap = 0;
  @Input() scanFrameWidthPercentage: number;
  @Input() scanFrameHeightPercentage: number;
  @Input() backText: string;
  @Input() backTextClass: string;
  @Input() backIcon: string;
  @Input() backImage: string;
  @Input() modalMode = false;
  @Input() formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  @Input() appearance: MatFormFieldAppearance;
  /** Emits when the scanner reads something. */
  @Output() readonly scanResult: EventEmitter<string> = new EventEmitter<string>();
  /** Emits when the scanner is closed. */
  @Output() readonly closeScanner: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** The scanner. */
  @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent;

  autoStart = false;
  hasDevices: boolean;
  hasCameras = false;
  hasPermission = false;
  qrResultString: string;
  availableDevices: MediaDeviceInfo[] = [];
  deviceCurrent: MediaDeviceInfo = null;
  deviceSelected: string;

  iosPlatform: boolean = /iPad|iPhone|iPod|CriOS/.test(navigator.userAgent);

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  /**
   * Constructor.
   * @param location
   * @param snackBarService
   * @param changeDetectorRef
   * @param defaultAppearance
   */
  constructor(private location: Location,
              private snackBarService: SnackBarService,
              private changeDetectorRef: ChangeDetectorRef,
              @Optional() @Inject(BEE_FORM_ITEM_DEFAULT_APPEARANCE) private defaultAppearance: MatFormFieldAppearance) {
    (defaultAppearance) ? this.appearance = defaultAppearance : this.appearance = 'outline';
  }

  /**
   * OnInit lifecycle, checks for cameras and permissions.
   */
  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  async onCamerasFound(devices: MediaDeviceInfo[]) {
    const hasPermission: boolean = await this.scanner.askForPermission();
    if (hasPermission) {
      if (devices.length && devices[0].deviceId) {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);

        const matcher = ({label}) => /back|trás|rear|traseira|environment|ambiente/gi.test(label);
        // select the rear camera by default, otherwise take the last camera.
        const backDevices = this.availableDevices.filter(matcher);
        if (backDevices.length) {
          this.onDeviceChange(backDevices[backDevices.length - 1]);
        } else if (this.availableDevices.length) {
          this.onDeviceChange(this.availableDevices[this.availableDevices.length - 1]);
        }
      } else if (devices.length) {
        this.scanner.updateVideoInputDevices();
      }
    }
  }

  onCamerasNotFound($event: any) {
    this.snackBarService.openSnackBar((localStorage.getItem('lang') === 'es') ? 'No se ha encontrado ninguna cámara, inténtalo de nuevo.' : 'No camera found, try again.');
  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || null;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  /**
   * Forces a camera permission check.
   */
  askForCameraPermission() {
    this.scanner.askForPermission().then((answer: boolean) => {
      this.hasPermission = answer;
    });
  }

  /**
   * Handles the QR scan result.
   * @param resultString
   */
  handleQrCodeResult(resultString: string) {
    // console.log('Result: ', resultString);
    this.qrResultString = resultString;
    this.scanResult.next(this.qrResultString);
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  /**
   * Navigates back.
   */
  goToPreviousPage() {
    this.closeScanner.emit(true);
    if (!this.modalMode) {
      this.location.back();
      window.scroll(0, 0);
    }
  }

  /**
   * OnDestroy lifecycle.
   */
  ngOnDestroy(): void {
    this.changeDetectorRef.detach();
  }
}
