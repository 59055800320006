import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@app/shared/services';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {
  domain = '';
  modalMode = false;
  lang = (localStorage.getItem('lang') === 'es') ? 'es' : 'en';
  contactName: string = '';
  contactPhone: string = '';
  nif: string = '';
  address: string = '';
  zipCode: string = '';
  city: string = '';
  register: string = '';
  loading: boolean = true;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
              @Optional() public dialogRef: MatDialogRef<LegalNoticeComponent>,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.loading = true;
    this.domain = (this.data.domain === undefined) ? undefined : this.data.domain.domain;
    if (this.domain) {
      this.register = '.';
      this.apiService.get('events/' + this.data.domain.id + '/infoAccountEvent').subscribe(data33 => {
        this.contactName = data33[0].contactName === null ? 'BRACELIT' : data33[0].contactName;
        this.contactPhone = data33[0].contactPhone === null ? '611015551' : data33[0].contactPhone;
        this.nif = data33[0].nif === null ? 'B19650704' : data33[0].nif;
        this.address = data33[0].address === null ? 'Calle Puentezuelas, número 3, 2ºB' : data33[0].address;
        this.zipCode = data33[0].zipCode === null ? '18002' : data33[0].zipCode;
        this.city = data33[0].city === null ? 'Granada (España)' : data33[0].city;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
    } else {
      this.register = this.lang === 'es' ? ', está inscrita en el Registro Mercantil de Granada, Tomo 1640, Folio 195, Hoja GR-50633.' : ', is registered in the Mercantile Registry of Granada, Volume 1640, Folio 195, Page GR-50633.';
      this.contactName = 'BRACELIT';
      this.contactPhone = '611015551';
      this.nif = 'B19650704';
      this.address = 'Calle Puentezuelas, número 3, 2ºB';
      this.zipCode = '18002';
      this.city = 'Granada (España)';
      this.loading = false;
    }
  }

  onGoBack() {
    this.dialogRef.close();
  }
}
