<div *ngIf="eventId !== 'fce88cca-0a62-4081-b364-1f9e6bef9fcb' && eventId !== '74f94c04-14fa-4e9d-8e9d-703d1c7ea11e'" style="display: flex; flex-direction: column; color:#0f223e">
  <div style="display: flex; justify-content:center; margin-top: 12px; margin-bottom: 16px; font-size: 20px; font-weight: bold">Selecciona tu fotografía favorita</div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 1</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox"  class="checkoption" name="foto1" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto1.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 2</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto2" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto2.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 3</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto3" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto3.jpg">
    </div>
  </div>


  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 4</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto4" (change)="checkedOnClick($event)" >
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto4.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 5</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto5" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto5.jpg">
    </div>
  </div>


  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 6</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto6" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto6.jpg">
    </div>
  </div>


  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 7</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto7" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto7.jpg">
    </div>
  </div>


  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 8</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto8" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto8.jpg">
    </div>
  </div>


  <!-- <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 9</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto9" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto9.jpg">
    </div>
  </div>


  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 10</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto10" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto10.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 11</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto11" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto11.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 12</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto12" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto12.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 13</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto13" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto13.jpeg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 14</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto14" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto14.JPG">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 15</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto15" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto15.jpg">
    </div>
  </div>

  <div style="display: flex; justify-content:center; margin-top: 14px; margin-bottom: 5px; font-size: 14px; font-weight: bold">Foto 16</div>
  <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom:16px">
    <div style="display: flex; align-items: center; width: 10%">
      <input type="checkbox" class="checkoption" name="foto16" (change)="checkedOnClick($event)">
    </div>
    <div style="width: 90%; font-weight: bold">
      <img style="width: 90%;" src="app/shared/assets/images/Foto16.jpg">
    </div>
  </div> -->

  <div style="display: flex; justify-content: center; margin-top: 16px">
    <button *ngIf="boton_deshabilitado" type="submit" class="primary-background bold-20px-center-white"
    style="width: 40%; height: 40px; border-radius: 7px; border-width: 0px; box-shadow: none; color: rgba(255, 255, 255, 0.5) !important">
    ENVIAR
    </button>
    <button *ngIf="!boton_deshabilitado" type="submit" class="primary-background bold-20px-center-white"
    style="width: 40%; height: 40px; border-radius: 7px; border-width: 0px; box-shadow: none" (click)="sendQuiz()">
    ENVIAR
    </button>
  </div>
</div>
<div *ngIf="eventId === 'fce88cca-0a62-4081-b364-1f9e6bef9fcb'">
  <script type="text/javascript"
    src="https://www.covermanager.com/js/iframeResizer.min.js">
  </script>
  <bracelit-preloader *ngIf="!loadIframe" [absoluteCentered]="true"></bracelit-preloader>
  <iframe
    id="lataja-caseta" title="Reservas"
    src="https://www.covermanager.com/reservation/module_restaurant/lataja-caseta/spanish"
    frameborder="0" height="550" width="100%" onload="iFrameResize();" (load)="iframeLoaded()">
  </iframe>
</div>
<div *ngIf="eventId === '74f94c04-14fa-4e9d-8e9d-703d1c7ea11e'" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
  <div style="display: flex !important; align-items:center !important; justify-content:right !important;
        height:fit-content !important; width:100% !important; padding-right: 24px;"
        (click)="onClose()">
        <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
  </div>
  <div style="padding: 20px;">
    Se va a proceder a pagar 1€ para la tokenización de su tarjeta bancaria como depósito por la entrega del material audioguía. Una vez devuelto el material se procederá a la devolución automática del importe y eliminación de todos los datos.
  </div>
  <button class="primary-background bold-20px-center-white"
    style="width: 40%; height: 40px; border-radius: 7px; border-width: 0px; box-shadow: none; margin-top: 40px; cursor: pointer;" (click)="goContinue()">
    Continuar
  </button>
</div>
